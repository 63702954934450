html {
  background-color: #1e1e1e;
  min-height: 100%;
}

.dashboard {
  padding: 20px;
  background-color: #1e1e1e;
  color: #ffffff;
  display: flex;  
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  overflow: auto;
}

.title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  margin-bottom: 20px;
  position: sticky;
  left: 0;
}

.positive {
  color: #00c82e;
}

.account-info {
  display: flex;
  gap: 1em;
  font-size: 13px;
  margin-bottom: 20px;

  .positive {
    color: #027bff;
  }
}



.negative {
  color: #ff4b4b;
}

.asset-section {
  margin-bottom: 20px;
}

.asset-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #888888;
}

.styled-table {
  height: 100%; /* Ensure the table takes full height */
  font-size: 0.8em; /* Adjust the font size as needed */
  width: 100%;
  border-collapse: collapse;
  background-color: #1e1e1e; /* Keep the black background */
}

.styled-table td {
  padding: 10px;
  text-align: left;

  &.data-cell-bold {
    background-color: #111111;
    font-weight: 900;
    font-size: 1em;
    color: #027bff;
    position: sticky;
    left: 0;
  }  
}

.header-cell {
  font-size: 0.8em; /* Adjust the font size as needed */
  color: #888888;
  width: 200px;
  white-space: pre;
  text-align: left;
  padding-bottom: 2em;

}

.data-row {
  border-bottom: 1px solid #333333;

  &:hover {
    .data-cell {
      .dashboard & {  
        background-color: #027bff;
        color: #ffffff;  
      }
    }
  }
}

.data-cell {
  font-size: 0.8em; /* Adjust the font size as needed */
  color: #ffffff;
  white-space: pre;
}

.data-cell-actions {
  height: 100%;
  display: flex;
  gap: 1px;
  align-items: stretch;
  justify-content: stretch; /* Ensure buttons stretch fully */
  padding: 0 !important;
}

.data-cell-actions button {
  height: 100%;
  flex-grow: 1; /* Ensures buttons expand to fill the cell */
}

.data-cell-actions .button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  cursor: pointer;
  white-space: pre;
}

.data-cell-actions .button:hover {
  background-color: #0056b3;
}

.data-cell-actions .button.positive {
  background-color: #00c82e; /* Green for positive profit/loss */
  color: #000000;
}

.data-cell-actions .button.negative {
  background-color: #ff0000; /* Red for negative profit/loss */
  color: #ffffff;
}

.data-cell.positive {
  color: #00c82e;
}

.data-cell.negative {
  color: #ff0000;
}

/* Add this to your existing CSS file */
.data-cell.updated {
  animation: highlight 1.5s linear;
}

@keyframes highlight {
  0% { background-color: #d5d501; }
  100% { background-color: transparent; }
}

.market-status .dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 10px;
  position: relative;
}

.market-status .dot.open {
  background-color: #00c82e; /* Green for open */
}

.market-status .dot.closed {
  background-color: #ff0000; /* Red for closed */
}