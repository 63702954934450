.graph {
  padding: 20px;
  background-color: #1e1e1e;
  color: #ffffff;
  display: flex;  
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  overflow: auto;

  .data-cell-bold {
    background-color: #1e1e1e;

    a {
      height: auto !important;
    }

    &:hover {
      .indicator {
        color: white;
      }
    }

    .indicator {
      padding: 0 1rem 1rem 1rem;
      color: #848484;
      font-size: 80%;
    }
  }
}

.chart-cell {
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.data-cell {
  &.market-status {
    padding: 0 !important;
    a {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 0.25em;
      padding: 1em; 
    }

    &:hover {
      background-color: #027bff;
      color: white !important;
    }
  }
}

.highlighted {
  a {
    background-color: #027bff;
    color: white;
  }

}