@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add these styles if not using Tailwind CSS */
.bg-green-500 {
  background-color: #48bb78; /* Tailwind green-500 color */
}

.bg-yellow-500 {
  background-color: #ecc94b; /* Tailwind yellow-500 color */
}

/* Add these styles if not using Tailwind CSS */
.w-24 {
  width: 6rem; /* Fixed width for table cells */
}

.text-sm {
  font-size: 0.875rem; /* Reduced font size for Reason column */
}

/* Add these styles if not using Tailwind CSS */
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.text-xs {
  font-size: 0.75rem;
}

/* Remove borders */
table, th, td {
  border: none;
}

tbody {
  tr {
    background-color: #111;
  }
}